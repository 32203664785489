import { getCookie } from 'cookies-next';

const getData = async (payload) => {
	try {
		const res = await fetch(`${process.env.BASE_URL2}/v1/statistic`, {
			method: 'POST',
			headers: {
				Authorization: getCookie('user_token')
			},
			body: JSON.stringify(payload)
		});
		const result = await res.json();

		return result;
	} catch (error) {
		console.log(error);
	}
};

const DashboardServices = {
	getData,
};

export default DashboardServices;