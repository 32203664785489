import CardContainer from '../PageLayout/CardContainer';
import SummaryCard from './summaryCard';
import SummaryDetails from './summaryDetails';

const AdjustmentSection = ({ state }) => {
	return (
		<CardContainer className=" col-span-12 space-y-3 p-2">
			<p className="font-bold text-2xl py-3">Total Adjustment</p>
			<div className="gap-5 grid grid-cols-8">

				<SummaryCard
					totalTickets={state.refinedData.totalDepositAdjusted}
					totalAmount={state.refinedData.totalDepositAdjustmenSuccessAmount}
					chartData={state.chartData.totalAdjustmentDepo}
					subData={state.refinedData.subDataAdjustmentDepo}
					barOptions={state.chartData.barOptions}
					status="success"
					title="deposit"
				/>

				<SummaryCard
					totalTickets={state.refinedData.totalWithdrawAdjusted}
					totalAmount={state.refinedData.totalWithdrawAdjustmenSuccessAmount}
					chartData={state.chartData.totalAdjustmentWd}
					subData={state.refinedData.subDataAdjustmentWd}
					barOptions={state.chartData.barOptions}
					status="failed"
					title="withdraw"
				/>

				<SummaryDetails data={state.refinedData.detailsDepositAdjustment} transactionType="deposit"
				/>

				<SummaryDetails data={state.refinedData.detailsWithdrawAdjustment} transactionType="withdraw"
				/>
			</div>
		</CardContainer>
	);
};

export default AdjustmentSection;
