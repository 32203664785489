import { Bar } from 'react-chartjs-2';
import typographyHelper from '../../../helpers/typography.helper';
import CardContainer from '../PageLayout/CardContainer';

const SummaryCard = ({
	totalTickets,
	totalAmount,
	subData,
	chartData,
	barOptions,
	status = 'success',
	title
}) => {
	return (
		<CardContainer className="col-span-2">
			<div className="mb-5">
				<div
					className={`
					text-black text-xl mb-5 p-5 font-semibold
					${status.toLowerCase() === 'success' && 'bg-[#daffdd]'}
					${status.toLowerCase() === 'failed' && 'bg-[#ffcdd2]'}
					${status.toLowerCase() === 'pending' && 'bg-[#ffe5a8]'}
				`}>
					{typographyHelper.toTitleCase(title)} ({totalTickets} Tickets)
				</div>
				<p className="text-3xl font-bold px-5">
					{typographyHelper.numberFormat(totalAmount, 2, 'USD')}
				</p>
			</div>
			<div className="space-y-3 px-5">
				<div className="flex gap-2 items-center">
					<div className="bg-[#4b94d0] h-20 w-2"></div>
					<div>
						<span className="text-sm block">Crypto:</span>
						<span className="text-xl block font-semibold">
							{typographyHelper.numberFormat(subData.crypto, 2, 'USD')}
						</span>
					</div>
				</div>

				<div className="flex gap-2 items-center">
					<div className="bg-[#014f8f] h-20 w-2"></div>
					<div>
						<span className="text-sm block">Fiat:</span>
						<span className="text-xl block font-semibold">
							{typographyHelper.numberFormat(subData.fiat, 2, 'USD')}
						</span>
					</div>
				</div>
			</div>

			<hr className="mt-5" />

			<div className="w-full mt-5 px-2 pb-8">
				<Bar data={chartData} options={barOptions} />
			</div>
		</CardContainer>
	);
};

export default SummaryCard;
