import CardContainer from '../PageLayout/CardContainer';
import SummaryCard from './summaryCard';
import SummaryDetails from './summaryDetails';

const WithdrawSection = ({ state }) => {
	return (
		<CardContainer className=" col-span-12 space-y-3 p-2">
			<p className="font-semibold text-2xl py-3">Total Withdraw</p>
			<div className="gap-5 grid grid-cols-8">

				<SummaryCard
					totalTickets={state.refinedData.totalWithdrawSuccess}
					totalAmount={state.refinedData.totalWithdrawSuccessAmount}
					subData={state.refinedData.subDataSuccessWithdrawAmount}
					chartData={state.chartData.totalWithdrawSuccess}
					barOptions={state.chartData.barOptions}
					status="success"
					title="success"
				/>

				<SummaryCard
					totalTickets={state.refinedData.totalWithdrawFailed}
					totalAmount={state.refinedData.totalWithdrawFailedAmount}
					subData={state.refinedData.subDataFailedWithdrawAmount}
					chartData={state.chartData.totalWithdrawFailed}
					barOptions={state.chartData.barOptions}
					status="failed"
					title="failed"
				/>

				<SummaryCard
					totalTickets={state.refinedData.totalWithdrawPending}
					totalAmount={state.refinedData.totalWithdrawPendingAmount}
					subData={state.refinedData.subDataPendingWithdrawAmount}
					chartData={state.chartData.totalWithdrawPending}
					barOptions={state.chartData.barOptions}
					status="pending"
					title="pending"
				/>

				<SummaryDetails data={state.detailsWithdraw} transactionType="withdraw" />
			</div>
		</CardContainer>
	);
};

export default WithdrawSection;
