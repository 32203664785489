const PageContainer = ({ title, note, className, children, titleClassname }) => {
	return (
		<div className={`w-full ${className}`}>
			{title && (
				<p
					className={`font-semibold text-xl mb-3 tracking-wide ${
						titleClassname && titleClassname
					}`}>
					{title}
				</p>
			)}
			{note && (
				<p className="font-bold text-sm mb-3">
					<span className="text-red-500">*</span>
					{note}
				</p>
			)}
			{children}
		</div>
	);
};

export default PageContainer;
