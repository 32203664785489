import { toast } from 'react-toastify';
import { create } from 'zustand';
import CurrencyServices from '../services/api/currency.services';

const GlobalStore = create((set) => ({
	toastStatus: { SUCCESS: 'success', ERROR: 'error', INFO: 'info' },
	newTransaction: {},
	setNewTransaction: (newTrx) => {
		set((state) => ({ ...state, newTransaction: newTrx }));
	},
	toast: (status, message) => toast[status](message),
	currencies: [],
	getCurrencies: () => {
		CurrencyServices.getAllCurrencyList({ type: 'get', currencyType: 'all' }).then((response) => {
			set((state) => ({ ...state, currencies: response }));
		});
	}
}));

export default GlobalStore;
