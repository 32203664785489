import moment from 'moment';

export const initData = {
	filter: {
		dateRange: [
			moment().startOf('day').toDate(),
			moment().endOf('day').toDate()
		],
		transactionType: ['deposit', 'withdraw'],
		currencies: []
	},
	chartData: {
		mainChart: {
			labels: [],
			datasets: []
		},
		newPlayerTotalDeposit: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0',
					borderWidth: 1
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f',
					borderWidth: 1
				}
			]
		},
		firstTimeTotalDeposit: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalDepositSuccess: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalDepositFailed: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalDepositPending: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalWithdrawSuccess: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalWithdrawFailed: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalWithdrawPending: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalAdjustmentDepo: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		totalAdjustmentWd: {
			labels: [''],
			datasets: [
				{
					label: 'Crypto',
					data: [0],
					backgroundColor: '#4b94d0',
					borderColor: '#4b94d0'
					// borderWidth: 1,
				},
				{
					label: 'USD',
					data: [0],
					backgroundColor: '#014f8f',
					borderColor: '#014f8f'
					// borderWidth: 1,
				}
			]
		},
		lineOptions: {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					position: 'bottom'
				}
			}
		},
		barOptions: {
			indexAxis: 'y',
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				}
			}
		}
	},
	detailsDeposit: {
		fiat: {},
		crypto: {}
	},
	detailsWithdraw: {
		fiat: {},
		crypto: {}
	},
	detailsDepoAdjustment: {
		fiat: {
		},
		crypto: {
			BTC: 0,
			ETH: 0,
			SHIB: 0,
			DOGE: 0,
			USDT: 0,
			BSC: 0,
			BIDR: 0,
			IDRT: 0,
		},
	},
	detailsWdAdjustment: {
		fiat: {
			USD: 0,
		},
		crypto: {
			BTC: 0,
			ETH: 0,
			SHIB: 0,
			DOGE: 0,
			USDT: 0,
			BSC: 0,
			BIDR: 0,
			IDRT: 0,
		},
	},
	currencies: [],
	refinedData: {},
	loading: true,
	refreshChart: true,
	data: [],
	error: ''
};