import React from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import typographyHelper from '../../../helpers/typography.helper';
import CardContainer from '../PageLayout/CardContainer';

const SummaryDetails = ({data, transactionType}) => {
	return (
		<CardContainer className=" col-span-2 overflow-hidden flex items-center relative">
			<div className="absolute top-0 right-0 py-1 px-3 bg-green-300 font-semibold rounded-bl-xl">
				Success
			</div>
			<div className="overflow-y-auto h-[30rem] pb-3 grid grid-cols-6 w-full space-y-4">
				<div className="col-span-6 space-y-2">
					<h6 className="px-3 text-center font-bold text-lg uppercase">CRYPTO {transactionType} DETAILS</h6>
				</div>

				<h6 className="col-span-2 my-auto pl-5 font-bold text-base">Currency</h6>
				<h6 className="col-span-3 my-auto text-right font-bold text-base">{typographyHelper.toTitleCase(transactionType)} Amount</h6>
				<p className="col-span-1 my-auto"></p>

				{Object.keys(data.crypto).map(currency => (
					<React.Fragment key={currency}>
						<p className="col-span-2 my-auto pl-5">{currency}</p>
						<p className="col-span-3 my-auto text-right">{typographyHelper.numberFormat(data.crypto[currency], 5)}</p>
						<p className="col-span-1 my-auto"></p>
					</React.Fragment>
				))}

				<hr className="col-span-6" />

				<div className="col-span-6 mt-6 space-y-2">
					<h6 className="px-3 text-center text-lg font-bold uppercase">FIAT {transactionType} DETAILS</h6>
				</div>
				<h6 className="col-span-2 my-auto pl-5 text-base font-bold">Currency</h6>
				<h6 className="col-span-3 my-auto text-right text-base font-bold">{typographyHelper.toTitleCase(transactionType)} Amount</h6>
				<p className="col-span-1 my-auto"></p>

				{Object.keys(data.fiat).map(currency => (
					<React.Fragment key={currency}>
						<p className="col-span-2 my-auto pl-5">{currency}</p>
						<p className="col-span-3 my-auto text-right">{typographyHelper.numberFormat(data.fiat[currency], 2)}</p>
						<RiErrorWarningLine
							className="col-span-1 cursor-pointer m-auto"
							onClick={() => {}}
							size={18}
						/>
					</React.Fragment>
				))}
			</div>
		</CardContainer>
	);
};

export default SummaryDetails;
