const PageLayout = ({ id, className, children }) => {
	return (
		<div
			id={id}
			className="relative w-full rounded-md shadow-[0_1rem_2rem_0_rgba(0,0,0,0.3)] p-[1rem]">
			<div className={`${className}`}>{children}</div>
		</div>
	);
};

export default PageLayout;
