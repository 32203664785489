import { getCookie } from 'cookies-next';

const getAllCurrencyList = async (payload) => {
	try {
		const res = await fetch(
			`${process.env.BASE_URL}/v1/get-list-currency`,
			{
				method: 'POST',
				headers: {
					Authorization: getCookie('user_token'),
				},
				body: JSON.stringify(payload),
			},
		);

		const result = await res.json();
		if (result.error) throw Error(result.message);

		return result.data;
	} catch (error) {
		throw error.message;
	}
};

const CurrencyServices = {
	getAllCurrencyList,
};

export default CurrencyServices;
