import moment from 'moment';

const toGMTZero = (date) => {
	return moment(date).tz('utc', true).format();
};

const toLocalZone = (date) => {
	return moment(date).format('yyyy-MM-DD HH:mm:ss');
};

const thisWeek = () => {
	const startOfWeek = moment().startOf('isoWeek').toString();
	const endOfWeek = moment().endOf('isoWeek').toString();
	
	return [startOfWeek, endOfWeek];
};

const utcTimeToUtcDate = (time) => {
	const momentUtc = moment.utc(time);
	const date = momentUtc.toDate();
	const offsetInMinutes = date.getTimezoneOffset();
	date.setMinutes(date.getMinutes() + offsetInMinutes);
	return date;
	// return moment.utc(time).tz('Etc/UTC').toDate();
};

const setDateTime = (time, format) => {
	return moment().set({year: time.year, month: time.month, date: time.date}).format(format);
};

const timeHelper = {
	toGMTZero,
	toLocalZone,
	thisWeek,
	utcTimeToUtcDate,
	setDateTime,
};

export default timeHelper;
