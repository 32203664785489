import { Loader } from 'rsuite';
import CardContainer from '../PageLayout/CardContainer';
import { Line } from 'react-chartjs-2';
import { useEffect } from 'react';

const MainChart = ({ state, handler, ACTION }) => {
	return (
		<CardContainer className="grid grid-cols-12 gap-10 col-span-12 p-5">
			<div className="h-80 w-full col-span-11 transition-all duration-500 relative">
				{state.refreshChart ? (
					<Loader center vertical content="Loading..." size="md" />
				) : (
					<Line data={state.chartData.mainChart} options={state.chartData.lineOptions} />
				)}
			</div>
			<div className="col-span-1 h-72 overflow-y-scroll overflow-x-hidden">
				<CurrenciesFilter data={state} dispatch={handler} ACTION={ACTION} />
			</div>
		</CardContainer>
	);
};

const CurrenciesFilter = ({ data, dispatch, ACTION }) => {
	const currencies = data.currencies.map((currency) => currency.currencyname);

	useEffect(() => {
		if (data.filter.currencies.length === 0) {
			dispatch({
				type: ACTION.SET,
				payload: { field: 'filter', name: 'currencies', value: currencies }
			});
		}
	}, [data.filter.currencies]);

	return (
		<div className="text-center w-20 space-y-px">
			<div
				className={`${
					data.filter.currencies.length === data.currencies.length ? 'bg-[#f4f4f4] text-black' : ''
				} p-1.5 select-none cursor-pointer transition-all duration-500`}
				onClick={() => {
					if (data.filter.currencies.length === currencies.length) return;
					dispatch({
						type: 'set',
						payload: {
							field: 'filter',
							name: 'currencies',
							value: data.filter.currencies.length !== data.currencies.length ? currencies : []
						}
					});
					dispatch({ type: ACTION.SET, payload: { name: 'refreshChart', value: true } });
				}}>
				ALL
			</div>

			{data.currencies.map((item) => (
				<div
					key={item.currencyid}
					className={`block p-1.5 select-none transition-all duration-500 cursor-pointer ${
						data.filter.currencies.includes(item.currencyname) &&
						data.filter.currencies.length !== currencies.length
							? ''
							: 'bg-[#f4f4f4] text-black'
					}`}
					onClick={() => {
						if (
							data.filter.transactionType.includes('deposit') &&
							data.filter.transactionType.includes('withdraw')
						) {
							dispatch({
								type: 'set',
								payload: { field: 'filter', name: 'currencies', value: [item.currencyname] }
							});
						}
						if (data.filter.currencies.length === currencies.length) {
							dispatch({
								type: 'set',
								payload: { field: 'filter', name: 'currencies', value: [item.currencyname] }
							});
							return;
						}
						if (
							(data.filter.transactionType.includes('deposit') &&
								!data.filter.transactionType.includes('withdraw')) ||
							(!data.filter.transactionType.includes('deposit') &&
								data.filter.transactionType.includes('withdraw'))
						) {
							dispatch({
								type: 'set',
								payload: {
									field: 'filter',
									name: 'currencies',
									value: data.filter.currencies.includes(item.currencyname)
										? data.filter.currencies.filter((d) => d !== item.currencyname)
										: [...data.filter.currencies, item.currencyname]
								}
							});
						}
						dispatch({ type: ACTION.SET, payload: { name: 'refreshChart', value: true } });
					}}>
					{item.currencyname}
				</div>
			))}
		</div>
	);
};

export default MainChart;
