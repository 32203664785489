import { Loader } from 'rsuite';

export default function LoadingModal({ open }) {
	return (
		<div
			className={`bg-white rounded-md fixed hover:cursor-pointer top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-28 w-40 border shadow-xl transition-all duration-500 ${
				open ? 'opacity-1 scale-1' : 'opacity-0 scale-0'
			}`}
		>
			<Loader center vertical content="Loading..." size="md" />
		</div>
	);
}
