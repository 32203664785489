import { startOfDay, endOfDay, subDays } from 'date-fns';
import moment from 'moment';

export const Ranges = [
	{
		label: 'Today',
		value: [startOfDay(moment().startOf('day').toDate()), endOfDay(moment().endOf('day').toDate())],
	},
	{
		label: 'Last 7 Days',
		value: [startOfDay(subDays(moment().startOf('day').toDate(), 6)), endOfDay(moment().endOf('day').toDate())],
	},
	{
		label: 'This Week',
		value: [startOfDay(moment().startOf('isoWeek').toDate()), endOfDay(moment().endOf('isoWeek').toDate())],
	},
	{
		label: 'Last Month',
		value: [
			startOfDay(moment().subtract(1, 'month').startOf('month').toDate()),
			endOfDay(moment().subtract(1, 'month').endOf('month').toDate()),
		],
	},
	{
		label: 'This Month',
		value: [
			startOfDay(moment().startOf('month').toDate()),
			endOfDay(moment().endOf('month').toDate()),
		],
	},
];
