import moment from 'moment';

function toTitleCase(str, invalidValue) {
	if (!str || str === ' ' || typeof str !== 'string') return invalidValue || '-';
	const strArr = str.split(' ');
	const changeToTileCase = strArr.map((w) => {
		return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase();
	});
	return changeToTileCase?.join(' ');
}

function numberInputFormat(val) {
	const numberRegex = /^[0-9.]+$/g;

	if (val === '') {
		val = '0';
	}

	if (!numberRegex.test(val)) return;

	if (val.charAt(0) == 0) {
		if (val.charAt(1) !== undefined && val.charAt(1) != '.') val = val.charAt(1);
	}

	return val;
}

function dateFormat(val, format = 'DD/MMM/yyyy', invalidValue) {
	const isValidDate = moment(val).isValid();
	if (!isValidDate || val === null || val === undefined) return invalidValue ? invalidValue : '';
	return moment(val).utcOffset(val).format(format);
}

function isTrue(options, val) {
	return !!val === true ? options[0] : options[1];
}

function numberFormat(val, maximumFractionDigits = 0, prefix) {
	if (isNaN(val)) return 0;
	return `${prefix && typeof prefix === 'string' ? prefix + ' ' : ''}${Intl.NumberFormat('en-US', {
		maximumFractionDigits: maximumFractionDigits
	}).format(val)}`;
}

function numberFormatCurrency(val, currency = '', prefix = '') {
	if (isNaN(val)) return '0';

	// Determine the maximum fraction digits based on currency
	const isFiat = currency === 'IDR' || currency === 'USD';
	const maximumFractionDigits = isFiat ? 2 : 5;

	// Format the number with the determined maximum fraction digits
	const formattedNumber = Intl.NumberFormat('en-US', {
		maximumFractionDigits: maximumFractionDigits
	}).format(val);

	// Prefix handling for non-fiat or custom prefix requirements
	const prefixString = !isFiat && prefix && typeof prefix === 'string' ? prefix + ' ' : '';

	return `${prefixString}${formattedNumber}`;
}

const typographyHelper = {
	toTitleCase,
	numberInputFormat,
	numberFormat,
	numberFormatCurrency,
	dateFormat,
	isTrue
};

export default typographyHelper;
