import PageLayout from '../src/components/organism/PageLayout/PageLayout';
import PageContainer from '../src/components/organism/PageLayout/PageContainer';
import { useEffect, useReducer } from 'react';
import moment from 'moment';
import { Button, ButtonGroup, DateRangePicker } from 'rsuite';
import typographyHelper from '../src/helpers/typography.helper';
import { Ranges } from '../src/constants/date-config';
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from 'chart.js/auto';
import LoadingModal from '../src/components/templates/Modal/LoadingModal';
import DashboardServices from '../src/services/api/dashboard.services';
import timeHelper from '../src/helpers/time.helper';
import { initData } from '../src/reducers/main/initData';
import MainChart from '../src/components/organism/Main/mainChart';
import PlayerTransaction from '../src/components/organism/Main/playerTransaction';
import DepositSection from '../src/components/organism/Main/depositSection';
import WithdrawSection from '../src/components/organism/Main/withdrawSection';
import AdjustmentSection from '../src/components/organism/Main/adjustmentSection';
import GlobalStore from '../src/store/global-store';

const reducer = (state, action) => {
	switch (action.type) {
		case 'change':
			return {
				...state,
				loading: true,
				[action.payload.field]: {
					...state[action.payload.field],
					[action.payload.name]: action.payload.value
				}
			};
		case 'get':
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case 'set':
			if (action.payload.field) {
				return {
					...state,
					[action.payload.field]: {
						...state[action.payload.field],
						[action.payload.name]: action.payload.value
					}
				};
			}
			return {
				...state,
				[action.payload.name]: action.payload.value
			};
		case 'set_sub_chart':
			return {
				...state,
				chartData: {
					...state.chartData,
					[action.payload.name]: {
						...state.chartData[action.payload.name],
						datasets: [
							{
								...state.chartData[action.payload.name].datasets[0],
								data: [action.payload.value.crypto]
							},
							{
								...state.chartData[action.payload.name].datasets[1],
								data: [action.payload.value.fiat]
							}
						]
					}
				}
			};
		case 'error':
			return {
				...state,
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
};

const ACTION = {
	CHANGE: 'change',
	GET: 'get',
	ERROR: 'error',
	SET: 'set',
	SET_SUB_CHART: 'set_sub_chart'
};

const Home = (p) => {
	const { currencies } = GlobalStore((state) => ({ currencies: state.currencies }));
	const [state, dispatch] = useReducer(reducer, initData);

	useEffect(() => {
		if (currencies.length) {
			getCurrencies(currencies);
		}
	}, [currencies]);

	useEffect(() => {
		// if (state.filter.currencies.length < 1) return;
		// if (state.filter)
		if (state.filter.currencies.length) getData();
		// if (!state.filter.currencies.length) {
		// 	getData();
		// }
	}, [state.filter.currencies, state.filter.dateRange]);

	useEffect(() => {
		processData();
	}, [state.data, state.refreshChart]);

	const accumulateAmount = (data, prop) => {
		return data.reduce((accumulator, currentValue) => accumulator + currentValue[prop], 0);
	};

	const accumulateAmountPerCurrencyType = (data, currencies) => {
		let deposit = {};
		let withdraw = {};

		Object.keys(currencies).forEach((currency) => {
			deposit = {
				...deposit,
				[currency]: data.reduce(
					(accumulator, currenctValue) =>
						accumulator + currenctValue[`total_deposit_amount_origin_${currency.toLowerCase()}`],
					0
				)
			};

			withdraw = {
				...withdraw,
				[currency]: data.reduce(
					(accumulator, currenctValue) =>
						accumulator + currenctValue[`total_withdraw_amount_origin_${currency.toLowerCase()}`],
					0
				)
			};
		});

		return [deposit, withdraw];
	};

	const currencyTypeAmount = (data, transactionType, currencyType, status) => {
		const [obj] = data.filter(
			(d) =>
				d.status.toLowerCase() === status.toLowerCase() &&
				d.currencytype.toLowerCase() === currencyType.toLowerCase() &&
				d.transactiontype.toLowerCase() === transactionType.toLowerCase()
		);

		return obj?.coalesce;
	};

	const refineDetailAdjustment = (d) => {
		const obj = {
			withdraw: {
				fiat: {},
				crypto: {}
			},
			deposit: {
				fiat: {},
				crypto: {}
			}
		};

		d.forEach((tx) => {
			obj[tx.txtype][tx.currencytype.toLowerCase()] = {
				...obj[tx.txtype][tx.currencytype.toLowerCase()],
				[tx.currencyname]: tx.coalesce
			};
		});

		return [obj.deposit, obj.withdraw];
	};

	const getData = () => {
		const payload = {
			type: 'brand',
			fromDate: timeHelper.toGMTZero(state.filter.dateRange[0]),
			toDate: timeHelper.toGMTZero(state.filter.dateRange[1]),
			transactionType: ['deposit', 'withdraw'],
			currency: state.filter.currencies
		};

		if (!state.filter.dateRange) return;

		DashboardServices.getData(payload)
			.then((result) => {
				if (result.error) throw Error(result.message);

				const depositAdjustmentDepoData = result.body.depowd.adjustamountData.filter(
					(d) => d.txtype === 'deposit'
				);
				const withdrawAdjustmentDepoData = result.body.depowd.adjustamountData.filter(
					(d) => d.txtype === 'withdraw'
				);

				const totalNewPlayerDepo = accumulateAmount(result.body.depowd.res1, 'new_player_deposit');
				const totalDepositSuccess = accumulateAmount(
					result.body.depowd.res1,
					'total_deposit_success'
				);
				const totalDepositAdjusted = accumulateAmount(
					depositAdjustmentDepoData,
					'count_adjustment'
				);
				const totalWithdrawAdjusted = accumulateAmount(
					withdrawAdjustmentDepoData,
					'count_adjustment'
				);
				const totalDepositPending = accumulateAmount(
					result.body.depowd.res1,
					'total_deposit_pending'
				);
				const totalDepositFailed = accumulateAmount(
					result.body.depowd.res1,
					'total_deposit_failed'
				);
				const totalDepositSuccessAmount = accumulateAmount(
					result.body.depowd.res1,
					'total_deposit_amount_success'
				);
				const totalDepositFailedAmount = accumulateAmount(
					result.body.depowd.res1,
					'total_deposit_amount_failed'
				);
				const totalDepositPendingAmount = accumulateAmount(
					result.body.depowd.res1,
					'total_deposit_amount_pending'
				);
				const totalWithdrawSuccess = accumulateAmount(
					result.body.depowd.res1,
					'total_withdraw_success'
				);
				const totalWithdrawPending = accumulateAmount(
					result.body.depowd.res1,
					'total_withdraw_pending'
				);
				const totalWithdrawFailed = accumulateAmount(
					result.body.depowd.res1,
					'total_withdraw_failed'
				);
				const totalWithdrawSuccessAmount = accumulateAmount(
					result.body.depowd.res1,
					'total_withdraw_amount_success'
				);
				const totalWithdrawFailedAmount = accumulateAmount(
					result.body.depowd.res1,
					'total_withdraw_amount_failed'
				);
				const totalWithdrawPendingAmount = accumulateAmount(
					result.body.depowd.res1,
					'total_withdraw_amount_pending'
				);

				const [fiatDepositDetails, fiatWithdrawDetails] = accumulateAmountPerCurrencyType(
					result.body.depowd.res1,
					state.detailsDeposit.fiat
				);
				const [cryptoDepositDetails, cryptoWithdrawDetails] = accumulateAmountPerCurrencyType(
					result.body.depowd.res1,
					state.detailsDeposit.crypto
				);

				const successDepositFiatAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'deposit',
					'fiat',
					'success'
				);
				const pendingDepositFiatAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'deposit',
					'fiat',
					'pending'
				);
				const failedDepositFiatAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'deposit',
					'fiat',
					'failed'
				);

				const successWithdrawFiatAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'withdraw',
					'fiat',
					'success'
				);
				const pendingWithdrawFiatAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'withdraw',
					'fiat',
					'pending'
				);
				const failedWithdrawFiatAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'withdraw',
					'fiat',
					'failed'
				);

				const successDepositCryptoAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'deposit',
					'crypto',
					'success'
				);
				const pendingDepositCryptoAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'deposit',
					'crypto',
					'pending'
				);
				const failedDepositCryptoAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'deposit',
					'crypto',
					'failed'
				);

				const successWithdrawCryptoAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'withdraw',
					'crypto',
					'success'
				);
				const pendingWithdrawCryptoAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'withdraw',
					'crypto',
					'pending'
				);
				const failedWithdrawCryptoAmount = currencyTypeAmount(
					result.body.depowd.res2,
					'withdraw',
					'crypto',
					'failed'
				);

				const firstTimeDepositCrypto =
					result.body.depowd.res3.filter((item) => item.currencytype.toLowerCase() === 'crypto')[0]
						?.sum || 0;
				const firstTimeDepositFiat =
					result.body.depowd.res3.filter((item) => item.currencytype.toLowerCase() === 'fiat')[0]
						?.sum || 0;

				const adjustmentDepositFiat =
					result.body.depowd.adjustamountData.filter(
						(item) =>
							item.currencytype.toLowerCase() === 'fiat' && item.txtype.toLowerCase() === 'deposit'
					)[0]?.coalesce || 0;
				const adjustmentDepositCrypto =
					result.body.depowd.adjustamountData.filter(
						(item) =>
							item.currencytype.toLowerCase() === 'crypto' &&
							item.txtype.toLowerCase() === 'deposit'
					)[0]?.coalesce || 0;

				const adjustmentWithdrawFiat =
					result.body.depowd.adjustamountData.filter(
						(item) =>
							item.currencytype.toLowerCase() === 'fiat' && item.txtype.toLowerCase() === 'withdraw'
					)[0]?.coalesce || 0;
				const adjustmentWithdrawCrypto =
					result.body.depowd.adjustamountData.filter(
						(item) =>
							item.currencytype.toLowerCase() === 'crypto' &&
							item.txtype.toLowerCase() === 'withdraw'
					)[0]?.coalesce || 0;

				const [detailsDepositAdjustment, detailsWithdrawAdjustment] = refineDetailAdjustment(
					result.body.depowd.detailAdjustmentData
				);
				const { totalPlayersLogin, totalPlayersPlayed } = result.body.totalPlayerStatistic;
				const newRegisteredPlayers = typographyHelper.numberFormat(
					result.body.depowd.totalActivity[0].registered_players
				);

				const refinedData = {
					totalNewPlayerDepo: totalNewPlayerDepo,
					totalDepositSuccess: typographyHelper.numberFormat(totalDepositSuccess, 0),
					totalDepositPending: typographyHelper.numberFormat(totalDepositPending, 0),
					totalDepositFailed: typographyHelper.numberFormat(totalDepositFailed, 0),
					totalDepositSuccessAmount: totalDepositSuccessAmount,
					totalDepositFailedAmount: totalDepositFailedAmount,
					totalDepositPendingAmount: totalDepositPendingAmount,
					totalWithdrawSuccess: typographyHelper.numberFormat(totalWithdrawSuccess, 0),
					totalWithdrawPending: typographyHelper.numberFormat(totalWithdrawPending, 0),
					totalWithdrawFailed: typographyHelper.numberFormat(totalWithdrawFailed, 0),
					totalWithdrawSuccessAmount: totalWithdrawSuccessAmount,
					totalWithdrawFailedAmount: totalWithdrawFailedAmount,
					totalWithdrawPendingAmount: totalWithdrawPendingAmount,
					totalDepositAdjusted: typographyHelper.numberFormat(totalDepositAdjusted, 0),
					totalWithdrawAdjusted: typographyHelper.numberFormat(totalWithdrawAdjusted, 0),
					totalDepositAdjustmenSuccessAmount: adjustmentDepositFiat + adjustmentDepositCrypto,
					totalWithdrawAdjustmenSuccessAmount: adjustmentWithdrawFiat + adjustmentWithdrawCrypto,
					detailsDepositAdjustment: detailsDepositAdjustment,
					detailsWithdrawAdjustment: detailsWithdrawAdjustment,
					playerStatistic: {
						totalPlayerLogins: typographyHelper.numberFormat(totalPlayersLogin),
						totalPlayersPlayed: typographyHelper.numberFormat(totalPlayersPlayed),
						newRegisteredPlayers: newRegisteredPlayers
					},
					subDataTotalFirstTimeDeposit: {
						fiat: firstTimeDepositFiat,
						crypto: firstTimeDepositCrypto
					},
					subDataSuccessDepositAmount: {
						fiat: successDepositFiatAmount,
						crypto: successDepositCryptoAmount
					},
					subDataPendingDepositAmount: {
						fiat: pendingDepositFiatAmount,
						crypto: pendingDepositCryptoAmount
					},
					subDataFailedDepositAmount: {
						fiat: failedDepositFiatAmount,
						crypto: failedDepositCryptoAmount
					},
					subDataSuccessWithdrawAmount: {
						fiat: successWithdrawFiatAmount,
						crypto: successWithdrawCryptoAmount
					},
					subDataPendingWithdrawAmount: {
						fiat: pendingWithdrawFiatAmount,
						crypto: pendingWithdrawCryptoAmount
					},
					subDataFailedWithdrawAmount: {
						fiat: failedWithdrawFiatAmount,
						crypto: failedWithdrawCryptoAmount
					},
					subDataAdjustmentDepo: {
						fiat: adjustmentDepositFiat,
						crypto: adjustmentDepositCrypto
					},
					subDataAdjustmentWd: {
						fiat: adjustmentWithdrawFiat,
						crypto: adjustmentWithdrawCrypto
					}
				};

				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'firstTimeTotalDeposit',
						value: { crypto: firstTimeDepositCrypto, fiat: firstTimeDepositFiat }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalAdjustmentDepo',
						value: { crypto: adjustmentDepositCrypto, fiat: adjustmentDepositFiat }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalAdjustmentWd',
						value: { crypto: adjustmentWithdrawCrypto, fiat: adjustmentWithdrawFiat }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalDepositSuccess',
						value: { crypto: successDepositCryptoAmount, fiat: successDepositFiatAmount }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalDepositFailed',
						value: { crypto: failedDepositCryptoAmount, fiat: failedDepositFiatAmount }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalDepositPending',
						value: { crypto: pendingDepositCryptoAmount, fiat: pendingDepositFiatAmount }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalWithdrawSuccess',
						value: { crypto: successWithdrawCryptoAmount, fiat: successWithdrawFiatAmount }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalWithdrawFailed',
						value: { crypto: failedWithdrawCryptoAmount, fiat: failedWithdrawFiatAmount }
					}
				});
				dispatch({
					type: ACTION.SET_SUB_CHART,
					payload: {
						name: 'totalWithdrawPending',
						value: { crypto: pendingWithdrawCryptoAmount, fiat: pendingWithdrawFiatAmount }
					}
				});
				dispatch({ type: ACTION.GET, payload: result.body.depowd.res1 });
				dispatch({ type: ACTION.SET, payload: { name: 'refreshChart', value: false } });
				dispatch({ type: ACTION.SET, payload: { name: 'refinedData', value: refinedData } });
				dispatch({
					type: ACTION.SET,
					payload: { field: 'detailsDeposit', name: 'fiat', value: fiatDepositDetails }
				});
				dispatch({
					type: ACTION.SET,
					payload: { field: 'detailsDeposit', name: 'crypto', value: cryptoDepositDetails }
				});
				dispatch({
					type: ACTION.SET,
					payload: { field: 'detailsWithdraw', name: 'fiat', value: fiatWithdrawDetails }
				});
				dispatch({
					type: ACTION.SET,
					payload: { field: 'detailsWithdraw', name: 'crypto', value: cryptoWithdrawDetails }
				});
			})
			.catch((err) => {
				p.toast.error(err.message);
			});
	};

	const getCurrencies = (currencieslist) => {
		const currencies = currencieslist.map((currency) => currency.currencyname);
		let fiat = {};
		let crypto = {};

		currencieslist
			.filter((currency) => currency.currencytype.toLowerCase() === 'fiat')
			.forEach((currency) => {
				fiat = { ...fiat, [currency.currencyname]: 0 };
			});

		currencieslist
			.filter((currency) => currency.currencytype.toLowerCase() === 'crypto')
			.forEach((currency) => {
				crypto = { ...crypto, [currency.currencyname]: 0 };
			});

		dispatch({ type: ACTION.SET, payload: { name: 'currencies', value: currencieslist } });
		dispatch({
			type: ACTION.SET,
			payload: { field: 'filter', name: 'currencies', value: currencies }
		});
		dispatch({
			type: ACTION.SET,
			payload: {
				field: 'detailsDeposit',
				name: 'fiat',
				value: fiat
			}
		});
		dispatch({
			type: ACTION.SET,
			payload: {
				field: 'detailsDeposit',
				name: 'crypto',
				value: crypto
			}
		});
	};

	// const getCurrencies = () => {
	// 	CurrencyServices.getAllCurrencyList({ type: 'get', currencyType: 'all' })
	// 		.then((result) => {
	// 			if (!result) throw Error();
	// 			const currencies = result.map((currency) => currency.currencyname);
	// 			let fiat = {};
	// 			let crypto = {};

	// 			result
	// 				.filter((currency) => currency.currencytype.toLowerCase() === 'fiat')
	// 				.forEach((currency) => {
	// 					fiat = { ...fiat, [currency.currencyname]: 0 };
	// 				});

	// 			result
	// 				.filter((currency) => currency.currencytype.toLowerCase() === 'crypto')
	// 				.forEach((currency) => {
	// 					crypto = { ...crypto, [currency.currencyname]: 0 };
	// 				});

	// 			dispatch({ type: ACTION.SET, payload: { name: 'currencies', value: result } });
	// 			dispatch({
	// 				type: ACTION.SET,
	// 				payload: { field: 'filter', name: 'currencies', value: currencies }
	// 			});
	// 			dispatch({
	// 				type: ACTION.SET,
	// 				payload: {
	// 					field: 'detailsDeposit',
	// 					name: 'fiat',
	// 					value: fiat
	// 				}
	// 			});
	// 			dispatch({
	// 				type: ACTION.SET,
	// 				payload: {
	// 					field: 'detailsDeposit',
	// 					name: 'crypto',
	// 					value: crypto
	// 				}
	// 			});
	// 		})
	// 		.catch((err) => console.log(err));
	// };

	const processData = () => {
		const d = {
			labels: [],
			datasets: []
		};

		const depoData = [];
		const wdData = [];

		const sortedData = state.data.sort((prev, next) => {
			if (prev.date > next.date) return 1;
			if (prev.date === next.date) return 0;
			return -1;
		});

		sortedData &&
			sortedData.forEach((item) => {
				d.labels.push(moment(item.date).format('DD/MMM'));
			});

		if (
			state.filter.transactionType.includes('deposit') &&
			state.filter.transactionType.includes('withdraw')
		) {
			state.filter.currencies.length > 1 &&
				sortedData.forEach((item) => {
					if (
						state.filter.transactionType.includes('deposit') &&
						state.filter.transactionType.includes('withdraw')
					) {
						depoData.push(item.total_deposit_amount_success);
						wdData.push(item.total_withdraw_amount_success);
					}
				});

			state.filter.currencies.length === 1 &&
				sortedData.forEach((item) => {
					if (
						state.filter.transactionType.includes('deposit') &&
						state.filter.transactionType.includes('withdraw')
					) {
						depoData.push(
							item[`total_deposit_amount_origin_${state.filter.currencies[0].toLowerCase()}_in_usd`]
						);
						wdData.push(
							item[
								`total_withdraw_amount_origin_${state.filter.currencies[0].toLowerCase()}_in_usd`
							]
						);
					}
				});

			d.datasets.push({
				label: 'Deposit',
				data: [...depoData]
			});

			d.datasets.push({
				label: 'Withdraw',
				data: [...wdData]
			});
		}

		if (
			state.filter.transactionType.includes('deposit') &&
			!state.filter.transactionType.includes('withdraw')
		) {
			state.filter.currencies.length > 0 &&
				state.filter.currencies.forEach((currency) => {
					const a = [];
					sortedData &&
						sortedData.forEach((item) => {
							a.push(item[`total_deposit_amount_origin_${currency.toLowerCase()}_in_usd`]);
						});
					depoData.push({
						label: currency,
						data: [...a]
					});
				});
			d.datasets.push(...depoData);
		}

		if (
			!state.filter.transactionType.includes('deposit') &&
			state.filter.transactionType.includes('withdraw')
		) {
			state.filter.currencies.length > 0 &&
				state.filter.currencies.forEach((currency) => {
					const a = [];
					sortedData &&
						sortedData.forEach((item) => {
							a.push(item[`total_withdraw_amount_origin_${currency.toLowerCase()}_in_usd`]);
						});
					wdData.push({
						label: currency,
						data: [...a]
					});
				});
			d.datasets.push(...wdData);
		}

		dispatch({
			type: ACTION.SET,
			payload: {
				field: 'chartData',
				name: 'mainChart',
				value: d
			}
		});

		dispatch({ type: ACTION.SET, payload: { name: 'refreshChart', value: false } });
	};

	if (state.loading) return <LoadingModal open={state.loading} />;

	return (
		<PageContainer title="Dashboard">
			<PageLayout className="space-y-5">
				<div className="flex justify-between w-full">
					<div className="flex gap-5 items-center">
						<DateRangePicker
							placeholder={typographyHelper.dateFormat(state.filter.dateFilter, 'DD/MMM/yyyy')}
							size="xs"
							appearance="subtle"
							className="shadow-card rounded-md p-2.5"
							cleanable={false}
							onChange={(date) => {
								dispatch({
									type: ACTION.CHANGE,
									payload: { field: 'filter', name: 'dateRange', value: date }
								});
							}}
							value={state.filter.dateRange}
							ranges={Ranges}
						/>
					</div>

					<ButtonGroup className="self-end shadow-card rounded-lg">
						<Button
							onClick={() => {
								dispatch({
									type: ACTION.CHANGE,
									payload: {
										field: 'filter',
										name: 'transactionType',
										value: ['deposit']
									}
								});
							}}
							className={`${
								state.filter.transactionType.includes('deposit') &&
								!state.filter.transactionType.includes('withdraw')
									? 'text-black bg-white'
									: 'bg-slate-100'
							}`}>
							Deposit
						</Button>
						<Button
							onClick={() => {
								dispatch({
									type: ACTION.CHANGE,
									payload: {
										field: 'filter',
										name: 'transactionType',
										value: ['withdraw']
									}
								});
							}}
							className={`${
								!state.filter.transactionType.includes('deposit') &&
								state.filter.transactionType.includes('withdraw')
									? 'text-black bg-white'
									: 'bg-slate-100'
							}`}>
							Withdraw
						</Button>
						<Button
							onClick={() => {
								dispatch({
									type: ACTION.CHANGE,
									payload: {
										field: 'filter',
										name: 'transactionType',
										value: ['deposit', 'withdraw']
									}
								});
							}}
							className={`${
								state.filter.transactionType.includes('deposit') &&
								state.filter.transactionType.includes('withdraw')
									? 'text-black bg-white'
									: 'bg-slate-100'
							}`}>
							Depoit and Withdraw
						</Button>
					</ButtonGroup>
				</div>

				<div className="grid grid-cols-12 col-span-12 gap-5 space-y-5 row-span-4">
					<MainChart state={state} handler={dispatch} ACTION={ACTION} />
					<PlayerTransaction state={state} />
					<DepositSection state={state} />
					<WithdrawSection state={state} />
					<AdjustmentSection state={state} />
				</div>
			</PageLayout>
		</PageContainer>
	);
};

// function SummaryModal({data, open, close}) {
// 	const {items, paymentCurrency} = data;

// 	return (
// 		<Modal open={open} onClose={() => close(false)} className="top-1/3">
// 			<Modal.Header></Modal.Header>
// 			<Modal.Body>
// 				<div className="space-y-5">
// 					<p className="text-xl">Payment Account Summary <span className="font-semibold">{paymentCurrency}</span></p>

// 					<table className="w-full">
// 						<thead>
// 							<tr>
// 								{/* <th>Payment Name</th> */}
// 								<th className="px-2">Account Name</th>
// 								<th className="px-2">Address</th>
// 								<th className="px-2">Amount</th>
// 							</tr>
// 						</thead>

// 						<tbody>
// 							{items?.map(item => (
// 								<tr key={item.address} className="text-center">
// 									<td>{item.accountName}</td>
// 									<td>
// 										<span className="truncate w-52 inline-block">{item.address}</span>
// 									</td>
// 									<td>{item.amount}</td>
// 								</tr>
// 							))}
// 						</tbody>
// 					</table>
// 				</div>
// 			</Modal.Body>
// 		</Modal>
// 	);
// }

export default Home;
