import CardContainer from '../PageLayout/CardContainer';
import SummaryCard from './summaryCard';
import SummaryDetails from './summaryDetails';

const DepositSection = ({ state }) => {
	return (
		<CardContainer className=" col-span-12 space-y-3 p-2">
			<p className="font-semibold text-2xl py-3">Total Deposit</p>
			<div className="gap-5 grid grid-cols-8">
				<SummaryCard
					totalTickets={state.refinedData.totalDepositSuccess}
					totalAmount={state.refinedData.totalDepositSuccessAmount}
					subData={state.refinedData.subDataSuccessDepositAmount}
					chartData={state.chartData.totalDepositSuccess}
					barOptions={state.chartData.barOptions}
					status="success"
					title="success"
				/>

				<SummaryCard
					totalTickets={state.refinedData.totalDepositFailed}
					totalAmount={state.refinedData.totalDepositFailedAmount}
					subData={state.refinedData.subDataFailedDepositAmount}
					chartData={state.chartData.totalDepositFailed}
					barOptions={state.chartData.barOptions}
					status="failed"
					title="failed"
				/>

				<SummaryCard
					totalTickets={state.refinedData.totalDepositPending}
					totalAmount={state.refinedData.totalDepositPendingAmount}
					subData={state.refinedData.subDataPendingDepositAmount}
					chartData={state.chartData.totalDepositPending}
					barOptions={state.chartData.barOptions}
					status="pending"
					title="pending"
				/>

				<SummaryDetails data={state.detailsDeposit} transactionType="deposit" />
			</div>
		</CardContainer>
	);
};

export default DepositSection;
