import { FaUser } from 'react-icons/fa';
import CardContainer from '../PageLayout/CardContainer';
import typographyHelper from '../../../helpers/typography.helper';
import { Bar } from 'react-chartjs-2';

const PlayerTransaction = ({ state }) => {
	return (
		<CardContainer className="col-span-12 grid grid-cols-12 p-2 gap-3">
			<CardContainer className="px-3 py-2 col-span-6">
				<div className="text-base flex items-center gap-3 font-semibold col-span-6">
					<span>First Time Deposit:</span>
					<div className="flex items-center gap-2">
						<FaUser className="inline-block text-sm"/>
						<span>{typographyHelper.numberFormat(state.refinedData.totalNewPlayerDepo)}</span>
					</div>
				</div>
				<div className="grid grid-cols-10 gap-3">
					<div className="col-span-4">
						<Bar data={state.chartData.firstTimeTotalDeposit} options={state.chartData.barOptions} />
					</div>
					<div className="flex gap-2 items-center col-span-3">
						<div className="bg-[#4b94d0] h-20 w-2"></div>
						<div>
							<span className="block text-sm">Crypto:</span>
							<span className="text-xl block font-semibold">
								{typographyHelper.numberFormat(state.refinedData.subDataTotalFirstTimeDeposit.crypto, 2, 'USD')}
							</span>
						</div>
					</div>

					<div className="flex gap-2 items-center col-span-3">
						<div className="bg-[#014f8f] h-20 w-2"></div>
						<div>
							<span className="block text-sm">Fiat:</span>
							<span className="text-xl block font-semibold">
								{typographyHelper.numberFormat(state.refinedData.subDataTotalFirstTimeDeposit.fiat, 2, 'USD')}
							</span>
						</div>
					</div>
				</div>
			</CardContainer>

			<CardContainer className="px-3 py-2 col-span-6 gap-10 justify-center flex">
				{/* <div className="p-3 self-center gap-4">
					<p className="text-lg font-semibold">Current Player Online:</p>
					<div className="flex justify-center items-center gap-2">
						<FaUser className="text-xl"/>
						<span className="text-3xl text-gray-500">0</span>
					</div>
				</div> */}

				<div className="p-3 self-center gap-4">
					<p className="text-lg font-semibold">Total Login Players:</p>
					<div className="flex justify-center items-center gap-2">
						<FaUser className="text-xl"/>
						<span className="text-3xl text-gray-500">{state.refinedData.playerStatistic.totalPlayerLogins}</span>
					</div>
				</div>

				<div className="p-3 self-center gap-4">
					<p className="text-lg font-semibold">Total Active Players:</p>
					<div className="flex justify-center items-center gap-2">
						<FaUser className="text-xl"/>
						<span className="text-3xl text-gray-500">{state.refinedData.playerStatistic.totalPlayersPlayed}</span>
					</div>
				</div>

				<div className="p-3 self-center gap-4">
					<p className="text-lg font-semibold">New Registered Players:</p>
					<div className="flex justify-center items-center gap-2">
						<FaUser className="text-xl"/>
						<span className="text-3xl text-gray-500">{state.refinedData.playerStatistic.newRegisteredPlayers}</span>
					</div>
				</div>
			</CardContainer>

			{/* <CardContainer className="px-3 py-2 col-span-6">
				<div className="text-base flex items-center gap-3 font-semibold col-span-6">
					<span>New Registered Deposit:</span>
					<div className="flex items-center gap-2">
						<FaUser className="inline-block text-sm"/>
						<span>{typographyHelper.numberFormat(state.refinedData.totalNewPlayerDepo)}</span>
					</div>
				</div>
				<div className="grid grid-cols-10 gap-3">
					<div className="col-span-4">
						<Bar data={state.chartData.newPlayerTotalDeposit} options={state.chartData.barOptions} />
					</div>
					<div className="flex gap-2 items-center col-span-3">
						<div className="bg-[#4b94d0] h-20 w-2"></div>
						<div>
							<span className="block text-sm">Crypto:</span>
							<span className="text-xl block font-semibold">
								{typographyHelper.numberFormat(0, 2, 'USD')}
							</span>
						</div>
					</div>

					<div className="flex gap-2 items-center col-span-3">
						<div className="bg-[#014f8f] h-20 w-2"></div>
						<div>
							<span className="block text-sm">Fiat:</span>
							<span className="text-xl block font-semibold">
								{typographyHelper.numberFormat(0, 2, 'USD')}
							</span>
						</div>
					</div>
				</div>
			</CardContainer> */}
		</CardContainer>
	);
};

export default PlayerTransaction;
